<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { usePostHogClient } from '~/domains/analytics/composables/usePostHogClient'
import { useAuthClient } from '~/domains/auth/auth-client'
import AuthFooter from '~/domains/auth/components/AuthFooter.vue'
import Logo from '~/domains/common/components/Logo.vue'
import { useLoginWithEmail } from '~/domains/users/mutations/useLoginWithEmail'
import { useGetUserProfile } from '~/domains/users/queries'

definePageMeta({
  title: 'auth.login.seo.title',
  layout: false,
  async middleware() {
    const localePath = useLocalePath()

    const { data: user, suspense } = useGetUserProfile()

    await suspense()

    if (user.value) {
      return navigateTo(localePath('/dashboard'))
    }
  },
})

const { t } = useI18n()
const toast = useToast()
const localePath = useLocalePath()
const posthog = usePostHogClient()
const route = useRoute()
const auth = useAuthClient()
const runtimeConfig = useRuntimeConfig()

const formState = ref({
  email: '',
})

const { isPending, isSuccess, mutate: requestOtp } = useLoginWithEmail({
  onError(error) {
    if (error.message.includes('USER_NOT_FOUND')) {
      return navigateTo(`/login?error=USER_NOT_FOUND`)
    }

    toast.add({
      color: 'red',
      title: t('auth.login.toasts.error.title'),
      icon: 'i-tabler-alert-triangle',
    })
  },
  async onSuccess(_, variables) {
    await navigateTo(localePath(`/auth/verify-otp?email=${variables.email}`))
  },
})

const { mutate: loginWithGoogle } = useMutation({
  async mutationFn() {
    posthog?.capture('user_logged_in', {
      provider: 'google',
    })

    await auth.value.signIn.social({
      provider: 'google',
      callbackURL: `${runtimeConfig.public.siteUrl}${localePath('/dashboard')}`,
      errorCallbackURL: `${runtimeConfig.public.siteUrl}${localePath('/login')}`,
    })
  },
  onError() {
    toast.add({
      color: 'red',
      title: t('auth.signupPage.toasts.error.title'),
      icon: 'i-tabler-alert-triangle',
    })
  },
})
</script>

<template>
  <main
    class="mx-auto flex h-dvh flex-col items-center justify-center gap-8 bg-gray-200 p-8 dark:bg-gray-900"
  >
    <NuxtLinkLocale to="/">
      <Logo class="fill-primary-500 w-40" />
    </NuxtLinkLocale>

    <div v-if="!isSuccess" class="flex w-full max-w-xs flex-col gap-4">
      <UButton
        block
        color="gray"
        trailing-icon="i-logos-google-icon"
        @click="loginWithGoogle"
      >
        {{ $t("auth.login.googleSignIn") }}
      </UButton>

      <UDivider
        :ui="{
          border: {
            base: 'border-gray-300 dark:border-gray-800',
          },
        }"
        :label="$t('auth.login.dividerLabel')"
      />

      <UForm
        :state="formState"
        class="flex w-full flex-col gap-4"
        @submit="({ data }) => requestOtp(data)"
      >
        <UFormGroup :label="$t('auth.login.fields.email.label')" name="email">
          <UInput v-model="formState.email" type="email" required />
        </UFormGroup>

        <!-- Honeypot 😈 -->
        <input type="text" name="company" class="hidden">

        <UButton
          type="submit"
          color="primary"
          size="lg"
          block
          :loading="isPending"
          trailing
        >
          {{ $t("auth.login.cta.idle") }}
        </UButton>
        <div v-if="route.query.error === 'USER_NOT_FOUND' || route.query.error === 'signup_disabled'" class="flex items-center gap-1">
          <UIcon
            name="i-tabler-alert-triangle"
            class="text-red-500"
          />
          <p className="text-red-500">
            {{ t('auth.errors.USER_NOT_FOUND') }}
          </p>
        </div>

        <i18n-t
          tag="p"
          keypath="auth.login.signUpHint.text"
          scope="global"
          class="text-center text-sm text-gray-600 dark:text-gray-400"
        >
          <template #link>
            <NuxtLinkLocale to="/signup" class="text-primary-500">
              {{ $t("auth.login.signUpHint.link") }}
            </NuxtLinkLocale>
          </template>
        </i18n-t>
      </UForm>
    </div>

    <AuthFooter class="mt-6" />
  </main>
</template>
