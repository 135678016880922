import { type MutationOptions, useMutation } from '@tanstack/vue-query'
import { usePostHogClient } from '~/domains/analytics/composables/usePostHogClient'

interface Params {
  email: string
}

export function useLoginWithEmail(
  options: Omit<MutationOptions<Awaited<ReturnType<typeof mutationFn>>, Error, Params>, 'mutationFn'> = {},
) {
  const { $trpc } = useNuxtApp()
  const posthog = usePostHogClient()

  async function mutationFn(data: Params) {
    posthog?.capture('user_requested_otp', {
      email: data.email,
    })

    return await $trpc.auth.requestOtp.mutate({ ...data, signup: false })
  }

  return useMutation({
    ...options,
    mutationFn,
  })
}
